import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>What's in Get Django Saas?</Subtitle>
      <SectionTitle>Pre-built features to save you time</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Admin Dashboard</FeatureTitle>
          <FeatureText>
            Manage your data and add new users  using Django admin dashboard
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>User managment</FeatureTitle>
          <FeatureText>
            Don't worry about login, signup, forgotten password or email invite pages. Get Django Saas handles it.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Email Integration</FeatureTitle>
          <FeatureText>
            Send automated emails using Sendgrid. All you need to do is add your API key and you'll be off to the race.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Payment</FeatureTitle>
          <FeatureText>
            Use Stripe or Paddle to get paid by your users.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Social Login</FeatureTitle>
          <FeatureText>Allow your users to log in with Google, Twitter or GitHub.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Teams</FeatureTitle>
          <FeatureText>
            Create teams according to your customers needs.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Docker support</FeatureTitle>
          <FeatureText>
            Don't want to polute your environment with various dependencies? You can use Docker to both develop your app and deploy it on production.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Heroku support</FeatureTitle>
          <FeatureText>
            Cutdown time to deploy by using Heroku. Get Django Saas is Heroku-friendly.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;

    text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`
